import { BondFullResponseDTO, ShareFullResponseDTO } from '@metaswiss/api';
import {
  BankIcon,
  CoinUnbrokenIcon,
  DetailsList,
  AppWizard,
  ResponsiveBill,
  SendIcon,
  useAppWizard,
  UserProfileCircle,
} from '@metaswiss/ui-kit';
import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ProductType } from '../../../../enums/productType.enum';
import { useMediaQuery } from '../../../../hooks/use-media-query/useMediaQuery';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { usePaymentContext } from '../context/PaymentContext';
import {
  BackgroundGradient,
  DetailsWrapper,
  MetaSwissBacgroundGradient,
  PaymentBillContainer,
  PaymentFlowContainer,
  Wrapper,
} from '../payment.styles';
import { BankTransfer } from '../steps/BankTransfer';
import { PurchaseStep } from '../steps/purchase-step/PurchaseStep';
import { getProductInfo } from '../utils/getProductInfo';

export const PaymentBondShare = () => {
  const theme = useTheme();

  const isMetaswiss = theme.v2.tenant === 'MetaSwiss'; // TODO: temporary solution

  const { item, quantity, productType, currency, totalAmount } = usePaymentContext();
  const product = item as BondFullResponseDTO | ShareFullResponseDTO | undefined;
  const { buttonText, currentStep, setWizardSteps, onNext, isButtonDisabled, isButtonLoading } = useAppWizard();
  const { textTranslation } = useTextTranslation();
  const navigate = useNavigate();
  const isBigScreen = useMediaQuery(breakpoints.desktopSm);
  const productInfo = useMemo(() => getProductInfo(productType, product, currency), [productType, product, currency]);

  const issuerDetails = {
    title: textTranslation('payment.issuerInfo'),
    isVisibleTitleStep: 1,
    items: [
      {
        icon: UserProfileCircle,
        subText: textTranslation('payment.paymentName'),
        text: product?.issuer.issuerName ?? '',
        isVisibleStep: 1,
      },
      {
        icon: SendIcon,
        subText: textTranslation('global.address'),
        text: product?.issuer.address ?? '',
        isVisibleStep: 1,
      },
    ],
  };

  const productDetails = {
    title:
      productType === ProductType.BOND_PAPERS
        ? textTranslation('payment.bondDetails')
        : textTranslation('payment.shareDetails'),
    isVisibleTitleStep: 1,
    items: [
      {
        icon: CurrencyChfIcon,
        subText:
          productType === ProductType.BOND_PAPERS
            ? textTranslation('payment.pricePerBond')
            : textTranslation('payment.pricePerShare'),
        text: productInfo?.productPrice || '',
        isVisibleStep: 1,
      },
      { icon: CoinUnbrokenIcon, subText: textTranslation('account.quantity'), text: `${quantity}`, isVisibleStep: 2 },
    ],
  };

  const paymentDetails = {
    title: textTranslation('portfolio.paymentDetails'),
    isVisibleTitleStep: 2,
    items: [
      {
        icon: BankIcon,
        subText: textTranslation('global.paymentMethod'),
        text: textTranslation('payment.bankTransfer'),
        isVisibleStep: 2,
      },
    ],
  };

  const steps = useMemo(
    () => [
      {
        title: textTranslation(productInfo.title),
        subtitle: textTranslation(productInfo.subTitle),
        children: <PurchaseStep />,
        buttonText: textTranslation('payment.proceedToPayment'),
        currentStep: 1,
        totalSteps: 2,
        prevStep: () => navigate(-1),
        dataAttributes: `payment-${productType === ProductType.BOND_PAPERS ? 'bond' : 'shares'}-proceed`,
      },
      {
        title: textTranslation('payment.bankTransfer'),
        subtitle: textTranslation('payment.bankTransfersSubtitle'),
        children: <BankTransfer />,
        buttonText: textTranslation('payment.submitReservation'),
        currentStep: 2,
        totalSteps: 2,
        dataAttributes: 'payment-submit-button',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productInfo]
  );

  useEffect(() => {
    setWizardSteps(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  const wizardExitAnimation = useMemo(() => {
    if (isBigScreen)
      return {
        left: '-50%',
        opacity: 0,
      };
    return {
      top: '-100%',
      opacity: 0,
    };
  }, [isBigScreen]);

  const billExitAnimation = useMemo(() => {
    if (isBigScreen)
      return {
        left: '100%',
        opacity: 0,
      };
    return {
      bottom: '-25%',
      opacity: 0,
    };
  }, [isBigScreen]);

  const transition = useMemo(() => {
    return { ease: 'easeInOut', duration: 0.4 };
  }, []);
  return (
    <Wrapper exit={{ overflow: 'hidden' }}>
      <PaymentFlowContainer exit={wizardExitAnimation} transition={transition}>
        <AppWizard />
      </PaymentFlowContainer>
      <PaymentBillContainer exit={billExitAnimation} transition={transition}>
        {isMetaswiss ? <MetaSwissBacgroundGradient /> : <BackgroundGradient />}
        <ResponsiveBill
          title={textTranslation('payment.overview')}
          amountText={textTranslation('payment.totalAmount')}
          amount={`${totalAmount} ${currency?.currencyCode}`}
          buttonText={buttonText}
          buttonDisabled={isButtonDisabled}
          buttonLoading={isButtonLoading}
          onClick={onNext}
        >
          <DetailsWrapper>
            <DetailsList {...issuerDetails} step={currentStep} />
            <DetailsList {...productDetails} step={currentStep} />
            <DetailsList {...paymentDetails} step={currentStep} />
          </DetailsWrapper>
        </ResponsiveBill>
      </PaymentBillContainer>
    </Wrapper>
  );
};
