import { BaseCard, Button, Text, ThemedIcon } from '@metaswiss/ui-kit';
import CircleBarChart from '@metaswiss/ui-kit/src/components/atoms/bar-chart/BarChart';
import { CoinsDouble } from '@metaswiss/ui-kit/src/iconography/CoinsDouble';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon';
import { useTextTranslation } from 'client/src/hooks/use-text-translation/useTextTranslation.ts';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { routes } from '../../../../../../router/routes.ts';
import { getChartPalette } from '../../../../../../shared/helpers/getChartColor.helper.ts';

import {
  BondCardHeader,
  BondInfoContainer,
  BondInfoWrapper,
  BondPriceInfoContainer,
  BondsInfoElementsContainer,
  BondsRemainingContainer,
  ButtonsWrapper,
  ChartWrapper,
  CountryFlag,
  FlagHeaderContainerWrapper,
  FlagWrapper,
  HeaderContainer,
  IconWrapper,
  PriceInfoWrapper,
  PricePerBondContainer,
} from './bondCard.styles.ts';
import { BondCardProps } from './types.ts';

export const BondCard: FC<BondCardProps> = ({ bond, onClick, handleInvestButtonClick }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const navigate = useNavigate();

  return (
    <BaseCard
      padding="0rem"
      disableStateEffects
      onClick={onClick}
      borderBox
      override={{
        borderRadius: theme.v2.radius.small,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <HeaderContainer>
        <BondCardHeader>
          <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
            {bond.issuer.issuerName}
          </Text>
          <FlagHeaderContainerWrapper>
            <Text color={theme.v2.text.headingPrimary}>{bond.issuer.country.name}</Text>
            <FlagWrapper>
              <CountryFlag src={`../../../assets/flags/${(bond.issuer.country.code as string).toLowerCase()}.svg`} />
            </FlagWrapper>
          </FlagHeaderContainerWrapper>
        </BondCardHeader>
      </HeaderContainer>
      <BondInfoContainer>
        <BondInfoWrapper>
          <BondsInfoElementsContainer>
            <BondPriceInfoContainer>
              <PricePerBondContainer>
                <IconWrapper>
                  <ThemedIcon icon={CoinsDouble} size="large" customStrokeColor={theme.v2.icon.default} />
                </IconWrapper>
                <PriceInfoWrapper>
                  <Text fontSize={'xsm'} color={theme.v2.text.disabled}>
                    {textTranslation('offering.annualInterest')}
                  </Text>
                  <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
                    {`${bond.interestRate.toString()} %`}
                  </Text>
                </PriceInfoWrapper>
              </PricePerBondContainer>
              <PricePerBondContainer>
                <IconWrapper>
                  <ThemedIcon icon={CurrencyChfIcon} size="medium" customStrokeColor={theme.v2.icon.default} />
                </IconWrapper>
                <PriceInfoWrapper>
                  <Text fontSize={'xsm'} color={theme.v2.text.disabled}>
                    {textTranslation('offering.denomination')}
                  </Text>
                  <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
                    {bond.denomination}
                  </Text>
                </PriceInfoWrapper>
              </PricePerBondContainer>
            </BondPriceInfoContainer>
            <BondsRemainingContainer>
              <ChartWrapper>
                <CircleBarChart
                  value={bond.availableBonds}
                  total={bond.totalBonds}
                  size={'3.75rem'}
                  fontSize={'base'}
                  color={getChartPalette(bond.totalBonds, bond.availableBonds)}
                />
              </ChartWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text fontWeight={'semi'} color={theme.v2.text.disabled}>
                  {textTranslation('offering.bondsRemaining')}
                </Text>
              </div>
            </BondsRemainingContainer>
          </BondsInfoElementsContainer>
          <ButtonsWrapper>
            <Button
              text={textTranslation('offering.viewDetails')}
              variant={'outlined'}
              fill
              size={'medium'}
              color={'neutral'}
              borderRadius={'medium'}
              onClick={() => navigate(`${routes.offering.smartBonds.root}/${bond.id}`)}
              dataAttributes={{ dataTestid: 'bond-viewdetails-button' }}
            />
            <Button
              text={textTranslation('offering.investNow')}
              fill
              size={'medium'}
              borderRadius={'medium'}
              color={'secondary'}
              onClick={handleInvestButtonClick}
              dataAttributes={{ dataTestid: 'bond-investnow-button' }}
            />
          </ButtonsWrapper>
        </BondInfoWrapper>
      </BondInfoContainer>
    </BaseCard>
  );
};
