/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetBondResponse } from '../models/AssetBondResponse';
import type { BondByTenantResponse } from '../models/BondByTenantResponse';
import type { BondFullResponseDTO } from '../models/BondFullResponseDTO';
import type { BondOfferingFullResponse } from '../models/BondOfferingFullResponse';
import type { CheckIsin } from '../models/CheckIsin';
import type { CountryResponse } from '../models/CountryResponse';
import type { OfferingResponse } from '../models/OfferingResponse';
import type { PageableItems } from '../models/PageableItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BondsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns boolean
   * @throws ApiError
   */
  public checkIsin(
    requestBody: CheckIsin,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/bonds/check-isin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @param companyName
   * @param currencyId
   * @param countryId
   * @param listingType
   * @returns any Get Bonds
   * @throws ApiError
   */
  public getBonds(
    offset?: number,
    limit?: number,
    page?: number,
    companyName?: string,
    currencyId?: string,
    countryId?: string,
    listingType?: 'STANDARD' | 'PREMIUM',
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bonds',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'companyName': companyName,
        'currencyId': currencyId,
        'countryId': countryId,
        'listingType': listingType,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns CountryResponse
   * @throws ApiError
   */
  public getBondCountries(): CancelablePromise<Array<CountryResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bonds/countries',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns BondByTenantResponse
   * @throws ApiError
   */
  public getTenantBonds(): CancelablePromise<BondByTenantResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bonds/tenant',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns OfferingResponse Bond tenant offering
   * @throws ApiError
   */
  public getTenantOffering(): CancelablePromise<OfferingResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bonds/offering',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns BondFullResponseDTO
   * @throws ApiError
   */
  public getBondById(
    id: string,
  ): CancelablePromise<BondFullResponseDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bonds/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param language
   * @returns BondOfferingFullResponse
   * @throws ApiError
   */
  public getBondOfferingById(
    id: string,
    language: string,
  ): CancelablePromise<BondOfferingFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bonds/offering/{id}',
      path: {
        'id': id,
      },
      query: {
        'language': language,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns AssetBondResponse
   * @throws ApiError
   */
  public getBondAssets(
    id: string,
  ): CancelablePromise<Array<AssetBondResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bonds/{id}/assets',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
