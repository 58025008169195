/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveCasesStatisticResponse } from '../models/ActiveCasesStatisticResponse';
import type { AddCaseDocumentsRequest } from '../models/AddCaseDocumentsRequest';
import type { CaseDetailsResponse } from '../models/CaseDetailsResponse';
import type { CaseDetailsV5Response } from '../models/CaseDetailsV5Response';
import type { DeleteCaseAssetsRequest } from '../models/DeleteCaseAssetsRequest';
import type { PageableItems } from '../models/PageableItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CaseApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ActiveCasesStatisticResponse Get User Active Cases Statistic
   * @throws ApiError
   */
  public getActiveCasesStatisticByUser(): CancelablePromise<ActiveCasesStatisticResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/active/statistic',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns CaseDetailsV5Response Get case details
   * @throws ApiError
   */
  public getPartialCaseDetails(
    id: string,
  ): CancelablePromise<CaseDetailsV5Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/{id}/details',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @returns any Get case conversation
   * @throws ApiError
   */
  public getCaseConversation(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/{id}/conversation',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get active cases by recipient
   * @throws ApiError
   */
  public getActiveCasesByUser(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/active',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get active cases by recipient
   * @throws ApiError
   */
  public getHistoryCasesByUser(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/history',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param recipientId
   * @param offset
   * @param limit
   * @param page
   * @returns any Get cases by recipient
   * @throws ApiError
   */
  public getCasesByUser(
    recipientId: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'recipientId': recipientId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Delete case assets
   * @throws ApiError
   */
  public deleteCaseAssets(
    requestBody: DeleteCaseAssetsRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v5/case',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param caseId
   * @param requestBody
   * @returns any Add case assets
   * @throws ApiError
   */
  public addCaseDocuments(
    caseId: string,
    requestBody: AddCaseDocumentsRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/case/add-case-assets',
      query: {
        'caseId': caseId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns CaseDetailsResponse Get case details
   * @throws ApiError
   */
  public getCaseDetails(
    id: string,
  ): CancelablePromise<CaseDetailsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
