export enum StripePaymentAction {
  SET_VALIDITY = 'SET_VALIDITY',
  SET_ERROR = 'SET_ERROR',
}

type FormStateData = {
  isCardNumberValid: boolean;
  isExpiryValid: boolean;
  isCvcValid: boolean;
  cardNumberErrorMessage: string;
  expiryErrorMessage: string;
  cvcErrorMessage: string;
};

type Action =
  | { type: StripePaymentAction.SET_VALIDITY; field: keyof FormStateData; value: boolean }
  | { type: StripePaymentAction.SET_ERROR; field: keyof FormStateData; value: string };

export const initialState: FormStateData = {
  isCardNumberValid: false,
  isExpiryValid: false,
  isCvcValid: false,
  cardNumberErrorMessage: '',
  expiryErrorMessage: '',
  cvcErrorMessage: '',
};

export const creditCardPaymentFormReducer = (state: FormStateData, action: Action) => {
  switch (action.type) {
    case StripePaymentAction.SET_VALIDITY:
      return { ...state, [action.field]: action.value };
    case StripePaymentAction.SET_ERROR:
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
