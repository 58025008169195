import { PrivateUserFullResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';

import { CreateEditPrivateUserData } from '../schemas/privateUserProfile.schema';

export const getPrivateUserDefaultValues = (privateUser: PrivateUserFullResponse): CreateEditPrivateUserData => {
  const { phoneNumber, phoneNumberPrefix } = privateUser;
  const { firstName, lastName, birthDate, citizenship } = privateUser.privateUser;
  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    phoneNumber: phoneNumber ?? '',
    birthDate: birthDate ? mapIsoStringToDate({ date: birthDate }) : '',
    phoneNumberPrefix: {
      label: phoneNumberPrefix,
      value: phoneNumberPrefix,
    },
    citizenship: {
      label: citizenship,
      value: citizenship,
    },
    picture: { fileId: '', url: '' },
  };
};
