import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { api } from '../../../../api/msApi';
import ControlledForm from '../../../../components/controlled-form/ControlledForm';
import { ApiResource } from '../../../../enums/resource.enum';
import { UserRole } from '../../../../enums/userRole';
import { AppState, useAppState } from '../../../../global-state/zustand';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

import { CorporateUserProfileForm } from './components/CorporateUserProfileForm';
import { PrivateUserProfileForm } from './components/PrivateUserProfileForm';
import { getCorporateUserDefaultValues } from './mappers/corporateUser.mapper';
import { getPrivateUserDefaultValues } from './mappers/privateUser.mapper';
import { createEditCorporateUserSchemaObject } from './schemas/corporateUserProfile.schema';
import { createEditPrivateUserSchemaObject } from './schemas/privateUserProfile.schema';

export const Profile = () => {
  const user = useAppState((state: AppState) => state.user);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { data: privateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.PRIVATE_USER, user?.id),
    queryFn: () => api.users.getPrivateUser(user!.id),
    enabled: !!user?.id && user?.role === UserRole.PRIVATE,
  });

  const { data: corporateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.CORPORATE_USER, user?.id),
    queryFn: () => api.users.getCorporateUser(user!.id),
    enabled: !!user?.id && user?.role === UserRole.CORPORATE,
  });

  return (
    <>
      {privateUser && (
        <ControlledForm
          validationSchema={createEditPrivateUserSchemaObject}
          defaultValues={getPrivateUserDefaultValues(privateUser)}
        >
          <PrivateUserProfileForm isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        </ControlledForm>
      )}
      {corporateUser && (
        <ControlledForm
          validationSchema={createEditCorporateUserSchemaObject}
          defaultValues={getCorporateUserDefaultValues(corporateUser)}
        >
          <CorporateUserProfileForm isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        </ControlledForm>
      )}
    </>
  );
};
