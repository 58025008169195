import { UpdatePrivateUserRequestV4 } from '@metaswiss/api';
import { mapDateToIsoString, swissPhonePrefix } from '@metaswiss/lib';
import {
  Button,
  CalendarIcon,
  ErrorDialog,
  FormDatePicker,
  FormInput,
  FormSelectionPopup,
  Globe04,
  HeaderCard,
  InputPhoneNumber,
  ItemProps,
  PageStateContainer,
  Text,
  TextLink,
  ThemedIcon,
  UploadAvatar,
} from '@metaswiss/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { AssignedUserAssetEnum } from '../../../../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../../global-state/zustand';
import { useCountries } from '../../../../../hooks/use-countries/useCountries';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../../shared/helpers/defaultUser';
import { findAssetByType } from '../../../../../shared/helpers/findAssetByType.helper';
import { getIsUserPictureUploaded } from '../../../../../shared/helpers/getIsUserPictureUploaded';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import {
  CreateEditPrivateUserData,
  createEditPrivateUserSchema,
  createEditPrivateUserSchemaNames,
} from '../schemas/privateUserProfile.schema';
import {
  AvatarContainer,
  ButtonWrapper,
  FullRowWrapper,
  HeaderContainer,
  ProfileInfoContainer,
  Wrapper,
} from '../styles/profile.styles';

type PrivateeUserProfileFormProps = {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
};
export const PrivateUserProfileForm = ({ isEditMode, setIsEditMode }: PrivateeUserProfileFormProps) => {
  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const {
    setValue,
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { isDirty, errors, isValid },
  } = useFormContext<CreateEditPrivateUserData>();
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);

  const { countriesPhoneNumberPrefixesAsDropdown, countriesNationalityAsDropdown } = useCountries();

  const [uploadedPicture, setUploadedPicture] = useState<{ fileId?: string; url?: string } | undefined>(undefined);
  const [unassignedUrlId, setUnassignedUrlId] = useState<string | undefined>(undefined);

  const queryClient = useQueryClient();

  const { mutate: updatePrivateUserMutation, isLoading: isUpdatingPrivateUser } = useMutation({
    mutationKey: getQueryKey(ApiResource.PRIVATE_USER, user.id),
    mutationFn: (data: CreateEditPrivateUserData) => {
      const updateData: Partial<UpdatePrivateUserRequestV4> = {
        birthDate: data?.birthDate ? mapDateToIsoString(data.birthDate) : undefined,
        citizenship: data.citizenship?.label,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        phoneNumberPrefix: data.phoneNumberPrefix.meta,
        picture: unassignedUrlId ? data.picture : undefined,
      };

      return api.users.updatePrivateDetails(user.id, updateData as UpdatePrivateUserRequestV4);
    },
    onSuccess: async () => {
      setIsEditMode(false);
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.ASSET, user.id) });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.PICTURE}/${user.id}`),
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.THUMBNAIL}/${user.id}`),
      });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PRIVATE_USER, user.id) });
      await refetchImage();
    },
  });

  const { mutate, isError } = useMutation({
    mutationFn: (file: File) => api.assets.uploadUnassignedFile({ file: file, fileType: file.type }),
    onSuccess: (response) => {
      setValue(
        createEditPrivateUserSchemaNames.picture,
        { fileId: response?.fileId, url: response?.url },
        { shouldValidate: true, shouldDirty: true }
      );
      setUnassignedUrlId(response.fileId);
      setUploadedPicture({ fileId: response?.fileId, url: response?.url });
    },
  });

  const onUpload = (file: File) => {
    mutate(file);
  };

  const swissPhonePrefixItem = useMemo(() => {
    return countriesPhoneNumberPrefixesAsDropdown.find((item: ItemProps) => item.meta === swissPhonePrefix);
  }, [countriesPhoneNumberPrefixesAsDropdown]);

  useEffect(() => {
    if (!getValues(createEditPrivateUserSchemaNames.phoneNumberPrefix)?.value && isEditMode && swissPhonePrefixItem) {
      setValue(createEditPrivateUserSchemaNames.phoneNumberPrefix, swissPhonePrefixItem);
    }
  }, [isEditMode, swissPhonePrefixItem, getValues, createEditPrivateUserSchemaNames.phoneNumberPrefix]);

  const handleDateSelection = (date: string) => {
    setValue(createEditPrivateUserSchemaNames.birthDate, date, { shouldValidate: true, shouldDirty: true });
  };

  const handlePhonePrefixSelection = (item: ItemProps) => {
    setValue(createEditPrivateUserSchemaNames.phoneNumberPrefix, item, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit = (data: CreateEditPrivateUserData) => {
    updatePrivateUserMutation(data);
    reset(data);
  };

  const { data: assets } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user?.id),
    queryFn: () => api.users.getCorporateUserAssets(user!.id),
    enabled: !!user?.role && !!user?.id,
  });

  const isPictureUploaded = getIsUserPictureUploaded(assets);

  const { data: imageUrl, refetch: refetchImage } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSIGNED_URL, findAssetByType(assets, AssignedUserAssetEnum.PICTURE)?.id),
    queryFn: () => {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      if (picture) {
        return api.assets.getS3SignedAssignedUrl({ assetId: picture?.id ?? '' });
      }
    },
    enabled: isPictureUploaded,
  });

  useEffect(() => {
    if (assets && imageUrl) {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      setUploadedPicture({ url: imageUrl?.url, fileId: picture?.id });
    }
  }, [assets, imageUrl]);

  const { refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.UNASSIGNED_URL),
    queryFn: () => {
      return api.assets.getS3SignedUnassignedUrl({ assetId: getValues('picture').fileId });
    },
    enabled: !!unassignedUrlId,
  });

  useEffect(() => {
    if (!unassignedUrlId) return;
    refetch().then((response) => {
      setUploadedPicture((prevState) => ({ url: response.data?.url, fileId: prevState?.fileId }));
    });
  }, [unassignedUrlId]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={false}
      showEmptyState={false}
      showError={false}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      {isEditMode && (
        <ErrorDialog
          primaryText={textTranslation('account.discardChanges')}
          secondaryText={textTranslation('account.areYouSureDiscard')}
          isOpen={isDiscardModalOpen}
          close={() => setIsDiscardModalOpen(false)}
          primaryButtonText={textTranslation('account.discard')}
          onPrimaryButtonClick={() => {
            refetchImage();
            reset();
            setIsDiscardModalOpen(false);
            setIsEditMode(false);
          }}
          secondaryButtonText={textTranslation('account.cancel')}
          onSecondaryButtonClick={() => setIsDiscardModalOpen(false)}
        />
      )}
      <HeaderCard
        header={
          <HeaderContainer>
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {textTranslation('account.profile')}
            </Text>
            <TextLink
              textSize="base"
              color={isEditMode ? 'error' : 'primary'}
              padding={'0.25rem'}
              dataTestId="profile-edit-button"
              onClick={() => {
                !isEditMode ? setIsEditMode(true) : isDirty ? setIsDiscardModalOpen(true) : setIsEditMode(false);
              }}
            >
              {isEditMode ? textTranslation('account.discard') : textTranslation('account.edit')}
            </TextLink>
          </HeaderContainer>
        }
        removeBackground={false}
        height={'fit-content'}
        removeOverflow={true}
      >
        <AvatarContainer>
          <UploadAvatar
            uploadImage={onUpload}
            imageSrc={uploadedPicture?.url}
            isError={isError}
            errorMessage={textTranslation('error.uploadError')}
            disabled={!isEditMode}
            isRequiredError={false}
          />
        </AvatarContainer>
        <Wrapper>
          <ProfileInfoContainer>
            <FormInput
              name={createEditPrivateUserSchemaNames.firstName}
              control={control}
              label={textTranslation('account.firstName')}
              error={errors[createEditPrivateUserSchemaNames.firstName]}
              fill
              isLocked={!isEditMode}
              dataAttributes={{ dataTestid: 'profile-first-name' }}
            />
            <FormInput
              name={createEditPrivateUserSchemaNames.lastName}
              control={control}
              label={textTranslation('account.lastName')}
              error={errors[createEditPrivateUserSchemaNames.lastName]}
              fill
              isLocked={!isEditMode}
              dataAttributes={{ dataTestid: 'profile-last-name' }}
            />
            <FormDatePicker
              name={createEditPrivateUserSchemaNames.birthDate}
              control={control}
              fill
              label={textTranslation('account.birthDate')}
              title={textTranslation('account.birthDate')}
              error={errors[createEditPrivateUserSchemaNames.birthDate]}
              dateSelectOptions={[
                textTranslation('global.day'),
                textTranslation('global.month'),
                textTranslation('global.year'),
              ]}
              isLocked={!isEditMode}
              selectText={textTranslation('selectDate')}
              closeBtnText={textTranslation('cancel')}
              confirmBtnText={textTranslation('confirm')}
              renderEndIcon={() => (
                <ThemedIcon icon={CalendarIcon} palette="primary" customStrokeColor={theme.v2.icon.primary} />
              )}
              onSelect={handleDateSelection}
              preferredLanguage={currentLanguage}
              isOptional={createEditPrivateUserSchema._def.shape().birthDate.isOptional()}
              dataAtrributeValue="profile-birth-date"
            />
            <FormSelectionPopup
              name={createEditPrivateUserSchemaNames.citizenship}
              control={control}
              error={errors[createEditPrivateUserSchemaNames.citizenship]?.value}
              itemsList={countriesNationalityAsDropdown}
              label={textTranslation('account.citizenship')}
              title={textTranslation('account.citizenship')}
              searchText={textTranslation('account.search')}
              renderEndIcon={() => (
                <ThemedIcon icon={Globe04} palette="primary" customStrokeColor={theme.v2.icon.primary} />
              )}
              isLocked={!isEditMode}
              fill
              closeButtonText={textTranslation('cancel')}
              selectButtonText={textTranslation('select')}
              isOptional={createEditPrivateUserSchema._def.shape().citizenship.isOptional()}
              dataAttributeValue={{ select: 'profile-citizenship' }}
            />
            <FullRowWrapper>
              <InputPhoneNumber
                name={createEditPrivateUserSchemaNames.phoneNumber}
                control={control}
                fill
                label={textTranslation('account.phoneNumber')}
                title={textTranslation('account.phoneNumberPrefix')}
                searchText={textTranslation('account.search')}
                itemList={countriesPhoneNumberPrefixesAsDropdown}
                error={errors[createEditPrivateUserSchemaNames.phoneNumber]}
                isLocked={!isEditMode}
                selectedItem={getValues(createEditPrivateUserSchemaNames.phoneNumberPrefix)}
                handleSelectedItem={handlePhonePrefixSelection}
                closeButtonText={textTranslation('cancel')}
                selectButtonText={textTranslation('select')}
                defaultItem={swissPhonePrefixItem}
                maxLength={16}
                dataAttributes={{ dataTestid: 'profile-phone-number' }}
              />
            </FullRowWrapper>
          </ProfileInfoContainer>
          {isEditMode && (
            <ButtonWrapper>
              <Button
                text={textTranslation('account.saveChanges')}
                fill
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty || !isValid}
                loading={isUpdatingPrivateUser}
                dataAttributes={{ dataTestid: 'profile-savechanges-button' }}
              />
            </ButtonWrapper>
          )}
        </Wrapper>
      </HeaderCard>
    </PageStateContainer>
  );
};
