import styled from 'styled-components';

export const ScaleTransitionWrapper = styled.div<{ $isCardClickable: boolean }>`
  transform: scale(1);
  transition: transform 100ms ease-out;
  transform-origin: top;

  &:hover {
    transform: ${({ $isCardClickable }) => $isCardClickable && 'scale(1.015)'};
  }

  &:active {
    transform: scale(1);
  }
`;
