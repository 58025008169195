import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { environmentVariables } from '../../env/environmentVariables';
import { ShellNavigationState, useShellNavigationState } from '../../global-state/zustand';
import { NavigationTabProps } from '../../shared/types/tabProps';
import { useGetCurrentRoute } from '../use-get-current-route/useGetCurrentRoute';

export const useSetRouteNavigation = (tabs: NavigationTabProps[], defaultRoute?: string) => {
  const { t } = useTranslation(['translation', environmentVariables.tenant], { nsMode: 'fallback' });
  const { setCurrentTabs, setSelectedTab, removeTabs, selectedTab } = useShellNavigationState(
    (state: ShellNavigationState) => state
  );
  const { locationPath } = useGetCurrentRoute();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentTabs(tabs);

    const selectedTab = tabs.find((tab) => tab.route === locationPath);
    if (defaultRoute && !selectedTab) {
      navigate(defaultRoute, { replace: true });
    }

    if (selectedTab) setSelectedTab(selectedTab);

    return () => removeTabs();
  }, [t, locationPath, removeTabs, setCurrentTabs, setSelectedTab, tabs, defaultRoute, navigate]);

  return {
    selectedTab,
  };
};
