import { CorporateUserFullResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';

import { CreateEditCorporateUserData } from '../schemas/corporateUserProfile.schema';

export const getCorporateUserDefaultValues = (
  corporateUser: CorporateUserFullResponse
): CreateEditCorporateUserData => {
  const { ceo, owner, dateFounded, legalForm, businessFocus } = corporateUser.corporateUser;
  const { phoneNumber, phoneNumberPrefix } = corporateUser;
  return {
    ceo: ceo ?? '',
    owner: owner ?? '',
    phoneNumber: phoneNumber ?? '',
    dateFounded: dateFounded ? mapIsoStringToDate({ date: dateFounded }) : '',
    picture: { fileId: '', url: '' },
    phoneNumberPrefix: {
      value: phoneNumberPrefix ?? '',
      label: phoneNumberPrefix ?? '',
    },
    legalForm: [
      {
        id: legalForm?.id ?? '',
        label: legalForm?.name ?? '',
      },
    ],
    businessFocus: {
      label: businessFocus?.name ?? '',
      value: businessFocus?.id ?? '',
    },
  };
};
