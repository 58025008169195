import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames.ts';
import { staticTranslationFunction } from '../../../../../shared/forms/staticTranslationFunction.ts';
import { regex } from '../../../../../shared/regex/regex.ts';

export const createEditCorporateUserSchemaObject = (translate: (key: string) => string) =>
  zod.object({
    ceo: zod.string().trim(),
    owner: zod.string().trim(),
    legalForm: zod.array(dropdownItemSchema).nonempty(translate('error.requiredField')),
    businessFocus: zod.object({
      value: zod.string(),
      label: zod.string(),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    dateFounded: zod.string(),
    phoneNumber: zod
      .string()
      .nonempty(translate('error.requiredField'))
      .regex(regex.numbersOnly, translate('error.invalidPhoneNumber'))
      .min(5, { message: translate('error.invalidPhoneNumberMinLength') })
      .max(15, { message: translate('error.invalidPhoneNumberMaxLength') }),
    picture: zod.object({
      fileId: zod.string(),
      url: zod.string(),
    }),
    phoneNumberPrefix: zod.object({
      value: zod.string(),
      label: zod.string(),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
  });

const dropdownItemSchema = zod.object({
  id: zod.string(),
  label: zod.string(),
});

export const createEditCorporateUserSchema = createEditCorporateUserSchemaObject(staticTranslationFunction);

export type CreateEditCorporateUserData = zod.infer<typeof createEditCorporateUserSchema>;

export const createEditCorporateUserSchemaNames: FormNames<CreateEditCorporateUserData> = {
  ceo: 'ceo',
  owner: 'owner',
  dateFounded: 'dateFounded',
  legalForm: 'legalForm',
  businessFocus: 'businessFocus',
  phoneNumber: 'phoneNumber',
  phoneNumberPrefix: 'phoneNumberPrefix',
  picture: 'picture',
} as const;
