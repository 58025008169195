export enum ErrorCode {
  GLOBAL_ERROR = 300,
  GLOBAL_FORM_ERROR = 310,
  GLOBAL_FIELD_ERROR = 320,
  FORM_ERROR = 330,
  MULTIPLE_PARAMS_ERROR = 340,
  NOT_APPROVED = 400,
  NO_PERMISSIONS = 410,
  INVALID_TOKEN = 498,
  INTERNAL_SERVER_ERROR = 500,
  REGION_ERROR = 405,
}
