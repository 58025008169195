import { ApiError, CancelablePromise, OpenAPIConfig, RefreshTokenResponse } from '@metaswiss/api';
import { ApiRequestOptions } from '@metaswiss/api/src/api/core/ApiRequestOptions';
import { ApiResult } from '@metaswiss/api/src/api/core/ApiResult';
import { OnCancel } from '@metaswiss/api/src/api/core/CancelablePromise';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosStatic from 'axios';
import FormData from 'form-data';

import { ErrorCode } from '../enums/errorCode';
import { useAppState } from '../global-state/zustand';

import {
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from './tokenHelpers';

type RejectFunction = (reason: unknown) => void;

const isDefined = <T>(value: T | null | undefined): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

const isStringWithValue = (value: unknown): value is string => {
  return isString(value) && value !== '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isBlob = (value: any): value is Blob => {
  return (
    typeof value === 'object' &&
    typeof value.type === 'string' &&
    typeof value.stream === 'function' &&
    typeof value.arrayBuffer === 'function' &&
    typeof value.constructor === 'function' &&
    typeof value.constructor.name === 'string' &&
    /^(Blob|File)$/.test(value.constructor.name) &&
    /^(Blob|File)$/.test(value[Symbol.toStringTag])
  );
};

const isFormData = (value: unknown): value is FormData => {
  return value instanceof FormData;
};

const isSuccess = (status: number): boolean => {
  return status >= 200 && status < 300;
};

const isError = (status: number): boolean => {
  return status >= 300;
};

const isRefreshResponse = (responseBody: unknown): responseBody is RefreshTokenResponse => {
  return (
    !!responseBody &&
    typeof responseBody === 'object' &&
    'accessToken' in responseBody &&
    typeof responseBody.accessToken === 'string'
  );
};

const base64 = (str: string): string => {
  try {
    return btoa(str);
  } catch (err) {
    return Buffer.from(str).toString('base64');
  }
};

const getQueryString = (params: Record<string, unknown>): string => {
  const qs: string[] = [];

  const append = (key: string, value: unknown) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const process = (key: string, value: unknown) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v);
        });
      } else if (typeof value === 'object' && !!value) {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v);
        });
      } else {
        append(key, value);
      }
    }
  };

  Object.entries(params).forEach(([key, value]) => {
    process(key, value);
  });

  if (qs.length > 0) {
    return `?${qs.join('&')}`;
  }

  return '';
};

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI;

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (!!options.path && Object.prototype.hasOwnProperty.call(options.path, group)) {
        return encoder(String(options.path[group]));
      }
      return substring;
    });

  const url = `${config.BASE}${path}`;
  if (options.query) {
    return `${url}${getQueryString(options.query)}`;
  }
  return url;
};

const getFormData = (options: ApiRequestOptions): FormData | undefined => {
  if (options.formData) {
    const formData = new FormData();

    const process = (key: string, value: unknown) => {
      if (isString(value) || isBlob(value)) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    };

    Object.entries(options.formData)
      .filter(([, value]) => isDefined(value))
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => process(key, v));
        } else {
          process(key, value);
        }
      });

    return formData;
  }
  return undefined;
};

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

const resolve = async <T>(options: ApiRequestOptions, resolver?: T | Resolver<T>): Promise<T | undefined> => {
  if (typeof resolver === 'function') {
    return (resolver as Resolver<T>)(options);
  }
  return resolver;
};

const getHeaders = async (
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  formData?: FormData
): Promise<Record<string, string>> => {
  const token = await resolve(options, config.TOKEN);
  const username = await resolve(options, config.USERNAME);
  const password = await resolve(options, config.PASSWORD);
  const additionalHeaders = await resolve(options, config.HEADERS);
  const formHeaders = (typeof formData?.getHeaders === 'function' && formData?.getHeaders()) || {};

  const headers = Object.entries({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Accept: 'application/json',
    ...additionalHeaders,
    ...options.headers,
    ...formHeaders,
  })
    .filter(([, value]) => isDefined(value))
    .reduce(
      (headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
      }),
      {} as Record<string, string>
    );

  if (isStringWithValue(token)) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  if (isStringWithValue(username) && isStringWithValue(password)) {
    const credentials = base64(`${username}:${password}`);
    headers['Authorization'] = `Basic ${credentials}`;
  }

  if (options.body) {
    if (options.mediaType) {
      headers['Content-Type'] = options.mediaType;
    } else if (isBlob(options.body)) {
      headers['Content-Type'] = options.body.type || 'application/octet-stream';
    } else if (isString(options.body)) {
      headers['Content-Type'] = 'text/plain';
    } else if (!isFormData(options.body)) {
      headers['Content-Type'] = 'application/json';
    }
  }

  return headers;
};

const getRequestBody = (options: ApiRequestOptions): unknown => {
  if (options.body) {
    return options.body;
  }
  return undefined;
};

const sendRequest = async <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  url: string,
  body: unknown,
  formData: FormData | undefined,
  headers: Record<string, string>,
  onCancel: OnCancel,
  axiosInstance: AxiosInstance
): Promise<AxiosResponse<T>> => {
  const source = axiosStatic.CancelToken.source();

  const requestConfig: AxiosRequestConfig = {
    url,
    headers,
    data: body ?? formData,
    method: options.method,
    withCredentials: config.WITH_CREDENTIALS,
    cancelToken: source.token,
  };

  onCancel(() => source.cancel('The user aborted a request.'));
  try {
    return await axiosInstance.request(requestConfig);
  } catch (error) {
    const axiosError = error as AxiosError<T>;
    if (axiosError.response) {
      return axiosError.response;
    }
    throw error;
  }
};

const getResponseHeader = (response: AxiosResponse<unknown>, responseHeader?: string): string | undefined => {
  if (responseHeader) {
    const content = response.headers[responseHeader];
    if (isString(content)) {
      return content;
    }
  }
  return undefined;
};

const getResponseBody = (response: AxiosResponse<unknown>): unknown => {
  if (response.status !== 204) {
    return response.data;
  }
  return undefined;
};

const catchErrorCodes = (options: ApiRequestOptions, result: ApiResult): void => {
  const errors: Record<number, string> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    400: 'Bad Request',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    401: 'Unauthorized',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    403: 'Forbidden',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    404: 'Not Found',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    500: 'Internal Server Error',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    502: 'Bad Gateway',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    503: 'Service Unavailable',
    ...options.errors,
  };

  const error = errors[result.status];
  let errorMessage = result.body?.message || '';

  if (result?.body?.code === ErrorCode.REGION_ERROR) {
    errorMessage = ErrorCode.REGION_ERROR;
  }

  if (result.status === 503) {
    useAppState.getState().setIsMaintenanceMode(true);
    throw new ApiError(options, result, errorMessage);
  }

  useAppState.getState().setIsMaintenanceMode(false);
  if (error) {
    throw new ApiError(options, result, errorMessage);
  }

  if (!result.ok) {
    throw new ApiError(options, result, errorMessage);
  }
};

/**
 * Request method
 * @param config - The OpenAPI configuration object
 * @param options - The request options from the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  axiosInstance: AxiosInstance
): CancelablePromise<T> => {
  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      let url = getUrl(config, options);
      let formData = getFormData(options);
      let body = getRequestBody(options);
      let headers = await getHeaders(config, options, formData);

      if (!onCancel.isCancelled) {
        let response = await sendRequest<T>(config, options, url, body, formData, headers, onCancel, axiosInstance);
        let responseBody = getResponseBody(response);
        let responseHeader = getResponseHeader(response, options.responseHeader);

        let successBody = responseHeader ?? responseBody;

        if (response.status === 401 && !url.includes('login')) {
          await updateRefreshToken(config, onCancel, reject, axiosInstance).then(async () => {
            url = getUrl(config, options);
            formData = getFormData(options);
            body = getRequestBody(options);
            headers = await getHeaders(config, options, formData);

            response = await sendRequest<T>(config, options, url, body, formData, headers, onCancel, axiosInstance);
            responseBody = getResponseBody(response);
            responseHeader = getResponseHeader(response, options.responseHeader);

            successBody = responseHeader ?? responseBody;
          });
        }

        const result: ApiResult = {
          url,
          ok: isSuccess(response.status),
          status: response.status,
          statusText: response.statusText,
          // @ts-expect-error - Ignoring because data is not defined on AxiosResponse
          body: isSuccess(response.status) ? successBody : response.data.payload,
        };

        catchErrorCodes(options, result);

        resolve(result.body);
      }
    } catch (error) {
      reject(error);
    }
  });
};
let isTriggeredRefreshUpdate = false;
const updateRefreshToken = async <T>(
  config: OpenAPIConfig,
  onCancel: OnCancel,
  reject: RejectFunction,
  axiosInstance: AxiosInstance
) => {
  const refreshToken = getRefreshToken();
  if (isTriggeredRefreshUpdate) {
    return;
  }
  const options: ApiRequestOptions = { method: 'POST', url: '/api/v4/auth/refresh', body: { refreshToken } };

  const url = getUrl(config, options);
  const formData = getFormData(options);
  const body = getRequestBody(options);
  const headers = await getHeaders(config, options, formData);

  try {
    isTriggeredRefreshUpdate = true;
    const response = await sendRequest<T>(config, options, url, body, formData, headers, onCancel, axiosInstance);
    isTriggeredRefreshUpdate = false;
    const error = isError(response.status);

    if (error) {
      removeAccessToken();
      removeRefreshToken();
      useAppState.getState().removeUser();
    } else {
      const responseBody = getResponseBody(response);

      if (isRefreshResponse(responseBody)) {
        setAccessToken(responseBody.accessToken);
        setRefreshToken(responseBody.refreshToken);
      }
    }
  } catch (error) {
    reject(error);
  }
};
