/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NftOfferingesponse } from '../models/NftOfferingesponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftCollectionCoreApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns NftOfferingesponse NFT Tenant offering
   * @throws ApiError
   */
  public getTenantOffering(): CancelablePromise<NftOfferingesponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/core/nft-collection/offering',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
