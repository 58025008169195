/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetResponse } from '../models/AssetResponse';
import type { CheckIsin } from '../models/CheckIsin';
import type { CountryResponse } from '../models/CountryResponse';
import type { OfferingResponse } from '../models/OfferingResponse';
import type { PageableItems } from '../models/PageableItems';
import type { ShareFullResponseDTO } from '../models/ShareFullResponseDTO';
import type { ShareOfferingFullResponse } from '../models/ShareOfferingFullResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SharesApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns CountryResponse Get share countries
   * @throws ApiError
   */
  public getShareCountries(): CancelablePromise<Array<CountryResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/countries',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param ownerId
   * @returns any Get share by owner id
   * @throws ApiError
   */
  public getShareStatusByOwnerId(
    ownerId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/status',
      query: {
        'ownerId': ownerId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param shareId
   * @param round
   * @returns any Get share status in specific round
   * @throws ApiError
   */
  public getShareRoundState(
    shareId: string,
    round: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/share-status',
      query: {
        'shareId': shareId,
        'round': round,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns OfferingResponse Share tenant offering
   * @throws ApiError
   */
  public getTenantOffering(): CancelablePromise<OfferingResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/offering',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Tenant share
   * @throws ApiError
   */
  public getTenantShare(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/tenant',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns ShareFullResponseDTO
   * @throws ApiError
   */
  public getShareByIdWithRelations(
    id: string,
  ): CancelablePromise<ShareFullResponseDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param language
   * @returns ShareOfferingFullResponse Share offering
   * @throws ApiError
   */
  public getShareOffering(
    id: string,
    language: string,
  ): CancelablePromise<ShareOfferingFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/offering/{id}',
      path: {
        'id': id,
      },
      query: {
        'language': language,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @param companyName
   * @param currencyId
   * @param countryId
   * @param listingType
   * @returns any
   * @throws ApiError
   */
  public getShares(
    offset?: number,
    limit?: number,
    page?: number,
    companyName?: string,
    currencyId?: string,
    countryId?: string,
    listingType?: 'STANDARD' | 'PREMIUM',
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'companyName': companyName,
        'currencyId': currencyId,
        'countryId': countryId,
        'listingType': listingType,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns AssetResponse
   * @throws ApiError
   */
  public getShareAssets(
    id: string,
  ): CancelablePromise<Array<AssetResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/{id}/assets',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns boolean
   * @throws ApiError
   */
  public checkIsin(
    requestBody: CheckIsin,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/shares/check-isin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns boolean
   * @throws ApiError
   */
  public checkHasShare(
    id: string,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/shares/{id}/check-has-share',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
