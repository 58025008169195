const getPercent = (total: number, value: number) => {
  if (total < 0 || value < 0 || value > total) {
    return 0;
  }
  if (value === 0) {
    return 100;
  }

  const percent = Math.round((value / total) * 100);

  return percent < 0.5 ? 1 : percent;
};

export const getChartPalette = (total: number, value: number) => {
  const percent = getPercent(total, value);

  if (percent >= 50) {
    return 'success';
  } else if (percent < 50 && percent >= 20) {
    return 'warning';
  } else {
    return 'error';
  }
};
