import { FC, memo, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { RemSize, TypographyLineHeight, TypographySize } from '../../../theme';

import { AnimatedCircle, Circle, Container, StyledText, Wrapper } from './styles/styledBarChart';

type BarChartProps = {
  total: number;
  value: number;
  size?: RemSize;
  fontSize?: TypographySize;
  lineHeight?: TypographyLineHeight;
  color: 'success' | 'warning' | 'error';
};

const CircleBarChart: FC<BarChartProps> = ({ total, value, size, fontSize = 'lg', lineHeight = 'medium', color }) => {
  const theme = useTheme();
  const percent = useMemo(() => {
    if (total < 0 || value < 0 || value > total) {
      return 0;
    }

    if (value === 0) {
      return 100;
    }
    const percent = Math.round((value / total) * 100);

    return percent < 0.5 ? 1 : percent;
  }, [total, value]);

  const circumference = useMemo(() => 2 * Math.PI * 40, []);
  const offset = useMemo(() => circumference * ((100 - percent) / 100), [circumference, percent]);

  return (
    <Wrapper $size={size}>
      <Container viewBox="5 5 90 90">
        <Circle r={40} cx={50} cy={50} />
        <AnimatedCircle circumference={circumference} offset={offset} r={40} cx={50} cy={50} $color={color} />
      </Container>
      <StyledText
        $color={theme.v2.icon[color]}
        $fontSize={fontSize}
        $fontWeight={'semi'}
        $lineHeight={lineHeight}
      >{`${percent}%`}</StyledText>
    </Wrapper>
  );
};

export default memo(CircleBarChart);
