import { CheckIcon, CloseSmallIcon, IconProps } from '@metaswiss/ui-kit';

import { RegistrationLength } from '../../../../../enums/registrationLength';

export type QucikRegistrationData = {
  title: RegistrationLength;
  info: QuickRegistrationInfo[];
};

export type QuickRegistrationInfo = {
  key: string;
  option: string;
  label: string;
  hasClose?: boolean;
  icon: React.FC<IconProps>;
};

const featureInfo = (registrationLength: RegistrationLength): QuickRegistrationInfo[] => {
  const buyBondShareIcon = registrationLength === RegistrationLength.FULL ? CheckIcon : CloseSmallIcon;
  return [
    { key: 'share', option: 'registration.view', label: 'registration.shares', icon: CheckIcon },
    { key: 'bond', option: 'registration.view', label: 'registration.bonds', icon: CheckIcon },
    { key: 'nft', option: 'registration.view', label: 'registration.collectibles', icon: CheckIcon },
    {
      key: 'share',
      option: 'registration.buy',
      label: 'registration.shares',
      hasClose: buyBondShareIcon === CloseSmallIcon,
      icon: buyBondShareIcon,
    },
    {
      key: 'bond',
      option: 'registration.buy',
      label: 'registration.bonds',
      hasClose: buyBondShareIcon === CloseSmallIcon,
      icon: buyBondShareIcon,
    },
    { key: 'nft', option: 'registration.buy', label: 'registration.collectibles', icon: CheckIcon },
  ];
};

export const getViewFeatureOptions = (permission: Record<string, boolean>): QucikRegistrationData[] => {
  return [
    {
      title: RegistrationLength.FULL,
      info: featureInfo(RegistrationLength.FULL).filter(({ key }) => permission[key]),
    },
    {
      title: RegistrationLength.QUICK,
      info: featureInfo(RegistrationLength.QUICK).filter(({ key }) => permission[key]),
    },
  ];
};
