import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import { EmailIcon, FormInput, Heading, LockIcon, Text, TextLink, ThemedIcon } from '@metaswiss/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../api/msApi';
import { setAccessToken, setRefreshToken } from '../../../api/tokenHelpers';
import ControlledForm from '../../../components/controlled-form/ControlledForm';
import { AppState, useAppState } from '../../../global-state/zustand';
import { useTenantConfig } from '../../../hooks/use-tenant-config/useTenantConfig';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';
import { AnimationContainer } from '../shared/animations/AnimationContainer';
import { useAuthContext } from '../shared/auth-context/authContext';
import { useSetAuthConfig } from '../shared/auth-context/useSetAuthConfig';

import { createLoginSchema, FormData, loginFormFields } from './loginSchema';
import { ForgotPasswordContainer, TextsWrapper, Wrapper } from './styles/login.styles';

export const LoginView = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const tenantConfig = useTenantConfig();
  const [badCredentials, setBadCredentials] = useState<boolean>(false);
  const { setLoading, clearAuthContext } = useAuthContext((state) => state);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useFormContext<FormData>();

  const navigate = useNavigate();
  const setUser = useAppState((state: AppState) => state.setUser);
  const { enqueue, clearQueue } = useToastQueueContext();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: FormData) => {
      return api.auth.login({ email: data.email, password: data.password });
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.wrongCredentials'), textTranslation('error.error'));
      setBadCredentials(true);
      reset({}, { keepValues: true });
    },
    onSuccess: (result) => {
      setAccessToken(result.accessToken);
      setRefreshToken(result.refreshToken);
      setUser(result.user);
      setLoading(false);
      if (result.user.firstTimeLogin) {
        navigate(routes.changePassword);
        return;
      }
      clearAuthContext();
      navigate(routes.root, { replace: true });
    },
  });

  const onSubmit = useCallback(() => {
    if (badCredentials) return;
    handleSubmit((data) => mutate(data))();
  }, [handleSubmit, setBadCredentials, mutate]);

  const navigateRoute = useCallback(() => {
    navigate(routes.register.root);
  }, [navigate]);

  useSetAuthConfig(
    {
      nextButton: {
        text: 'login.logIn',
        onClick: onSubmit,
        dataAttributes: { dataTestid: 'login-button' },
      },
      actionText: {
        text: 'login.dontHaveAccount',
        linkText: 'login.signUp',
        animationDirection: 'login-out',
        onClick: navigateRoute,
      },
    },
    [onSubmit, navigateRoute]
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    return () => clearQueue();
  }, [clearQueue]);

  useEffect(() => {
    if (badCredentials && isDirty) setBadCredentials(false);
  }, [badCredentials, isDirty]);

  return (
    <AnimationContainer>
      <TextsWrapper>
        <Heading headingType="h4">{textTranslation('login.welcome')}</Heading>
        <Heading headingType="h2" fontWeight="bold">
          {tenantConfig.appTitle}
        </Heading>
        <Text>{textTranslation('login.subtitle')}</Text>
      </TextsWrapper>
      <Wrapper>
        <FormInput<FormData>
          name={loginFormFields.email as Path<FormData>}
          control={control}
          label={textTranslation('login.email')}
          fill
          error={errors[loginFormFields.email]}
          type="text"
          renderStartIcon={() => <ThemedIcon icon={EmailIcon} customColor={theme.v2.icon.default} />}
          customErrorExists={badCredentials}
          dataAttributes={{ dataTestid: 'email-login' }}
        />
        <FormInput<FormData>
          name={loginFormFields.password as Path<FormData>}
          control={control}
          label={textTranslation('login.password')}
          fill
          type="password"
          error={errors[loginFormFields.password]}
          renderStartIcon={() => <ThemedIcon icon={LockIcon} customStrokeColor={theme.v2.icon.default} />}
          customErrorExists={badCredentials}
          dataAttributes={{ dataTestid: 'password-login' }}
        />
      </Wrapper>
      <ForgotPasswordContainer>
        <TextLink onClick={() => navigate(routes.forgotPassword)} textSize="base" dataTestId="forgotPass-button">
          {textTranslation('login.forgotPassword')}
        </TextLink>
      </ForgotPasswordContainer>
    </AnimationContainer>
  );
};

export const Login = () => {
  return (
    <ControlledForm validationSchema={createLoginSchema}>
      <LoginView />
    </ControlledForm>
  );
};
