/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BondTransactionV5Response } from '../models/BondTransactionV5Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BondTransactionsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id
   * @returns BondTransactionV5Response Bond transactions
   * @throws ApiError
   */
  public getBondTransactionById(
    id: string,
  ): CancelablePromise<BondTransactionV5Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/bond-transactions/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
