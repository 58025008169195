import { UpdateCorporateUserRequest } from '@metaswiss/api';
import { mapDateToIsoString, swissPhonePrefix } from '@metaswiss/lib';
import {
  Button,
  CalendarIcon,
  ErrorDialog,
  FormDatePicker,
  FormDropdown,
  FormInput,
  FormSelectionPopup,
  HeaderCard,
  InputPhoneNumber,
  ItemProps,
  LuggageIcon,
  PageStateContainer,
  Text,
  TextLink,
  ThemedIcon,
  UploadAvatar,
} from '@metaswiss/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { AssignedUserAssetEnum } from '../../../../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../../global-state/zustand';
import { useBusinesses } from '../../../../../hooks/use-businesses/useBusinesses';
import { useCountries } from '../../../../../hooks/use-countries/useCountries';
import { useLegalForm } from '../../../../../hooks/use-legal-form/useLegalForm';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../../shared/helpers/defaultUser';
import { findAssetByType } from '../../../../../shared/helpers/findAssetByType.helper';
import { getIsUserPictureUploaded } from '../../../../../shared/helpers/getIsUserPictureUploaded';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import {
  CreateEditCorporateUserData,
  createEditCorporateUserSchemaNames,
} from '../schemas/corporateUserProfile.schema';
import {
  AvatarContainer,
  ButtonWrapper,
  HeaderContainer,
  ProfileInfoContainer,
  Wrapper,
} from '../styles/profile.styles';

type CorporateUserProfileFormProps = {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
};
export const CorporateUserProfileForm = ({ isEditMode, setIsEditMode }: CorporateUserProfileFormProps) => {
  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const {
    setValue,
    control,
    getValues,
    reset,
    formState: { isDirty, errors, isValid },
    handleSubmit,
  } = useFormContext<CreateEditCorporateUserData>();
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);

  const { countriesPhoneNumberPrefixesAsDropdown } = useCountries();
  const { businesses } = useBusinesses();
  const { legalForms } = useLegalForm();

  const [uploadedPicture, setUploadedPicture] = useState<{ fileId?: string; url?: string } | undefined>(undefined);
  const [unassignedUrlId, setUnassignedUrlId] = useState<string | undefined>(undefined);

  const queryClient = useQueryClient();

  const { mutate, isError } = useMutation({
    mutationFn: (file: File) => api.assets.uploadUnassignedFile({ file: file, fileType: file.type }),
    onSuccess: (response) => {
      setValue(
        createEditCorporateUserSchemaNames.picture,
        { fileId: response?.fileId, url: response?.url },
        { shouldValidate: true, shouldDirty: true }
      );
      setUnassignedUrlId(response.fileId);
      setUploadedPicture({ fileId: response?.fileId, url: response?.url });
    },
  });

  const { mutate: updateCorporateUserMutation, isLoading: isUpdatingCorporateUser } = useMutation({
    mutationKey: getQueryKey(ApiResource.CORPORATE_USER, user?.id),
    mutationFn: (data: CreateEditCorporateUserData) => {
      const updateData: Partial<UpdateCorporateUserRequest> = {
        ceo: data.ceo,
        owner: data.owner,
        legalFormId: data.legalForm[0].id !== '' ? data.legalForm[0].id : undefined,
        businessFocusId: data.businessFocus.value !== '' ? data.businessFocus.value : undefined,
        phoneNumber: data.phoneNumber,
        phoneNumberPrefix: data.phoneNumberPrefix.meta,
        dateFounded: data?.dateFounded ? mapDateToIsoString(data.dateFounded) : undefined,
        picture: unassignedUrlId ? data.picture : undefined,
      };
      return api.users.updateCorporateDetails(user!.id, updateData as UpdateCorporateUserRequest);
    },
    onSuccess: async () => {
      setIsEditMode(false);
      setUnassignedUrlId(undefined);
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.ASSET, user.id) });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.PICTURE}/${user.id}`),
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.THUMBNAIL}/${user.id}`),
      });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CORPORATE_USER, user.id) });
      await refetchImage();
    },
  });

  const onUpload = (file: File) => {
    mutate(file);
  };

  const swissPhonePrefixItem = useMemo(() => {
    return countriesPhoneNumberPrefixesAsDropdown.find((item: ItemProps) => item.meta === swissPhonePrefix);
  }, [countriesPhoneNumberPrefixesAsDropdown]);

  useEffect(() => {
    if (!getValues(createEditCorporateUserSchemaNames.phoneNumberPrefix)?.value && isEditMode && swissPhonePrefixItem) {
      setValue(createEditCorporateUserSchemaNames.phoneNumberPrefix, swissPhonePrefixItem);
    }
  }, [isEditMode, swissPhonePrefixItem, getValues, createEditCorporateUserSchemaNames.phoneNumberPrefix]);

  const handleDateSelection = (date: string) => {
    setValue(createEditCorporateUserSchemaNames.dateFounded, date, { shouldValidate: true, shouldDirty: true });
  };

  const handlePhonePrefixSelection = (item: ItemProps) => {
    setValue(createEditCorporateUserSchemaNames.phoneNumberPrefix, item, { shouldValidate: true, shouldDirty: true });
  };

  const onSubmit = (data: CreateEditCorporateUserData) => {
    updateCorporateUserMutation(data);
    reset(data);
  };

  const { data: assets } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user?.id),
    queryFn: () => api.users.getCorporateUserAssets(user!.id),
    enabled: !!user?.role && !!user?.id,
  });

  const isPictureUploaded = getIsUserPictureUploaded(assets);

  const { data: imageUrl, refetch: refetchImage } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSIGNED_URL, findAssetByType(assets, AssignedUserAssetEnum.PICTURE)?.id),
    queryFn: () => {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      if (picture) {
        return api.assets.getS3SignedAssignedUrl({ assetId: picture?.id ?? '' });
      }
    },
    enabled: isPictureUploaded,
  });

  useEffect(() => {
    if (assets && imageUrl) {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      setUploadedPicture({ url: imageUrl?.url, fileId: picture?.id });
    }
  }, [assets, imageUrl]);

  const { refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.UNASSIGNED_URL),
    queryFn: () => {
      return api.assets.getS3SignedUnassignedUrl({ assetId: getValues('picture').fileId });
    },
    enabled: !!unassignedUrlId,
  });

  useEffect(() => {
    if (!unassignedUrlId) return;
    refetch().then((response) => {
      setUploadedPicture((prevState) => ({ url: response.data?.url, fileId: prevState?.fileId }));
    });
  }, [unassignedUrlId]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={false}
      showEmptyState={false}
      showError={false}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      {isEditMode && (
        <ErrorDialog
          primaryText={textTranslation('account.discardChanges')}
          secondaryText={textTranslation('account.areYouSureDiscard')}
          isOpen={isDiscardModalOpen}
          close={() => setIsDiscardModalOpen(false)}
          primaryButtonText={textTranslation('account.discard')}
          onPrimaryButtonClick={() => {
            refetchImage();
            reset();
            setIsDiscardModalOpen(false);
            setIsEditMode(false);
          }}
          secondaryButtonText={textTranslation('account.cancel')}
          onSecondaryButtonClick={() => setIsDiscardModalOpen(false)}
        />
      )}
      <HeaderCard
        header={
          <HeaderContainer>
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {textTranslation('account.profile')}
            </Text>
            <TextLink
              textSize="base"
              color={isEditMode ? 'error' : 'primary'}
              padding={'0.25rem'}
              dataTestId="profile-edit-button"
              onClick={() => {
                !isEditMode ? setIsEditMode(true) : isDirty ? setIsDiscardModalOpen(true) : setIsEditMode(false);
              }}
            >
              {isEditMode ? textTranslation('account.discard') : textTranslation('account.edit')}
            </TextLink>
          </HeaderContainer>
        }
        removeBackground={false}
        height={'fit-content'}
        removeOverflow={true}
      >
        <AvatarContainer>
          <UploadAvatar
            uploadImage={onUpload}
            imageSrc={uploadedPicture?.url}
            isError={isError}
            errorMessage={textTranslation('error.uploadError')}
            disabled={!isEditMode}
            isRequiredError={false}
          />
        </AvatarContainer>
        <Wrapper>
          <ProfileInfoContainer>
            <FormInput
              name={createEditCorporateUserSchemaNames.ceo}
              control={control}
              label={textTranslation('account.ceo')}
              error={errors[createEditCorporateUserSchemaNames.ceo]}
              fill
              isLocked={!isEditMode}
              alertIconShown={!getValues(createEditCorporateUserSchemaNames.ceo)}
              dataAttributes={{ dataTestid: 'profile-ceo' }}
            />
            <FormInput
              name={createEditCorporateUserSchemaNames.owner}
              control={control}
              label={textTranslation('account.owner')}
              error={errors[createEditCorporateUserSchemaNames.owner]}
              fill
              isLocked={!isEditMode}
              alertIconShown={!getValues(createEditCorporateUserSchemaNames.owner)}
              dataAttributes={{ dataTestid: 'profile-owner' }}
            />
            <FormDatePicker
              name={createEditCorporateUserSchemaNames.dateFounded}
              control={control}
              fill
              label={textTranslation('account.dateFounded')}
              title={textTranslation('account.dateFounded')}
              error={errors[createEditCorporateUserSchemaNames.dateFounded]}
              dateSelectOptions={[
                textTranslation('global.day'),
                textTranslation('global.month'),
                textTranslation('global.year'),
              ]}
              isLocked={!isEditMode}
              selectText={textTranslation('selectDate')}
              closeBtnText={textTranslation('cancel')}
              confirmBtnText={textTranslation('confirm')}
              renderEndIcon={() => (
                <ThemedIcon icon={CalendarIcon} palette="primary" customStrokeColor={theme.v2.icon.primary} />
              )}
              onSelect={handleDateSelection}
              preferredLanguage={currentLanguage}
              dataAtrributeValue="profile-date-founded"
            />
            <FormSelectionPopup
              name={createEditCorporateUserSchemaNames.businessFocus}
              control={control}
              error={errors[createEditCorporateUserSchemaNames.businessFocus]?.value}
              itemsList={businesses}
              label={textTranslation('account.businessFocus')}
              title={textTranslation('account.businessFocus')}
              searchText={textTranslation('account.search')}
              renderEndIcon={() => (
                <ThemedIcon icon={LuggageIcon} palette="primary" customStrokeColor={theme.v2.icon.primary} />
              )}
              isLocked={!isEditMode}
              fill
              closeButtonText={textTranslation('cancel')}
              selectButtonText={textTranslation('select')}
              dataAttributeValue={{ select: 'profile-business-focus' }}
            />
            <FormDropdown
              name={createEditCorporateUserSchemaNames.legalForm}
              error={errors[createEditCorporateUserSchemaNames.legalForm] as FieldError}
              control={control}
              options={legalForms}
              alertIconShown={true}
              fill
              isLocked={!isEditMode}
              label={textTranslation('account.legalForm')}
              dataAttributes={{ dataTestid: 'profile-legal-form' }}
            />
            <InputPhoneNumber
              defaultItem={swissPhonePrefixItem}
              name={createEditCorporateUserSchemaNames.phoneNumber}
              control={control}
              fill
              label={textTranslation('account.phoneNumber')}
              title={textTranslation('account.phoneNumberPrefix')}
              searchText={textTranslation('account.search')}
              itemList={countriesPhoneNumberPrefixesAsDropdown}
              error={errors[createEditCorporateUserSchemaNames.phoneNumber]}
              isLocked={!isEditMode}
              selectedItem={getValues(createEditCorporateUserSchemaNames.phoneNumberPrefix)}
              handleSelectedItem={handlePhonePrefixSelection}
              closeButtonText={textTranslation('cancel')}
              selectButtonText={textTranslation('select')}
              maxLength={16}
              dataAttributes={{ dataTestid: 'profile-phone-number' }}
            />
          </ProfileInfoContainer>
          {isEditMode && (
            <ButtonWrapper>
              <Button
                text={textTranslation('account.saveChanges')}
                fill
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty || !isValid}
                loading={isUpdatingCorporateUser}
                dataAttributes={{ dataTestid: 'profile-savechanges-button' }}
              />
            </ButtonWrapper>
          )}
        </Wrapper>
      </HeaderCard>
    </PageStateContainer>
  );
};
