import { Heading, Text } from '../../../components';

import {
  Container,
  ContentWrapper,
  DescriptionWrapper,
  DownloadAppImage,
  DownloadAppImagesContainer,
  DownloadAppImageWrapper,
  FixedLogo,
  FixedLogoWrapper,
  HeadingWrapper,
  LogoHolder,
  LogoImage,
} from './mobileAppScreen.styles';

type MobileAppScreenProps = {
  logo: string;
  googlePlayAppLink?: string;
  appStoreAppLink?: string;
  headingText: string;
  descriptionText: string;
};

export const MobileAppScreen = ({
  headingText,
  logo,
  googlePlayAppLink,
  appStoreAppLink,
  descriptionText,
}: MobileAppScreenProps) => {
  return (
    <Container>
      <ContentWrapper>
        <LogoHolder>
          <LogoImage src={logo} />
        </LogoHolder>
        <HeadingWrapper>
          <Heading headingType={'h3'} fontWeight={'bold'}>
            {headingText}
          </Heading>
        </HeadingWrapper>
        <DescriptionWrapper>
          <Text>{descriptionText}</Text>
        </DescriptionWrapper>
        {(googlePlayAppLink || appStoreAppLink) && (
          <DownloadAppImagesContainer>
            {googlePlayAppLink && (
              <DownloadAppImageWrapper>
                <DownloadAppImage
                  src="https://res.cloudinary.com/dmfsrdbdb/image/upload/v1704356683/google_ise5ax.png"
                  onClick={() => window.open(googlePlayAppLink, '_blank')}
                />
              </DownloadAppImageWrapper>
            )}
            {appStoreAppLink && (
              <DownloadAppImageWrapper>
                <DownloadAppImage
                  src="https://res.cloudinary.com/dmfsrdbdb/image/upload/v1704356688/apple_jnpsvw.png"
                  onClick={() => window.open(appStoreAppLink, '_blank')}
                />
              </DownloadAppImageWrapper>
            )}
          </DownloadAppImagesContainer>
        )}
      </ContentWrapper>
      <FixedLogoWrapper>
        <FixedLogo src={logo} />
      </FixedLogoWrapper>
    </Container>
  );
};
